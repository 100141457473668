.home {
  height: 94vh;
  width: 94vw;

  h2 {
    margin: 20px 0;
    text-align: center;
  }

  h4 {
    margin-top: 0 !important;
    margin-bottom: 20px;
    text-align: center;
  }

  .leftSide{
    .ant-descriptions-item-content{
      line-height: 30px;
    }
  }
  //
  //.ant-input-number{
  //  max-width: 70px;
  //}

}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.strong{
  font-weight: bold;
}

.ant-drawer-body{
  display: flex;
  align-items: center;
  justify-content: center;
}

.questions {
  min-height: 50vh;
  width: 50vw;
}

@primary-color: #1DA57A;