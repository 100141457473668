@import '~antd/dist/antd.dark.less';

body {
  margin: 0;
  min-height: 100vh;
  background: black;

  //transform: scale(1.5);
  //transform-origin: 0 0;

  display: flex;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  .ant-page-header{
    background-color: #202d46;
  }
}

@primary-color: #1DA57A;